import { Component, ReactNode } from "react";
import { logEvent } from "../firebase/analytics";

type ErrorBoundaryProps = {
    children: ReactNode;
    fallback?: ReactNode;
  };
  
  type ErrorBoundaryState = {
    hasError: boolean;
    error: Error | null;
  };
  
  class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, error: null };
    }
  
    static getDerivedStateFromError(error: Error) {
      // Update state so the next render shows the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      // console.error('Error caught by ErrorBoundary:', error, errorInfo);
      logEvent('LogError', {error, errorInfo});
      // Optionally log the error to an error reporting service like Sentry
    }
  
    resetError = () => {
      this.setState({ hasError: false, error: null });
    };
  
    render() {
      if (this.state.hasError) {
        const { fallback } = this.props;
  
        return (
          <div>
            {fallback || (
              <>
                <h2>Something went wrong:</h2>
                <pre>{this.state.error?.message}</pre>
                <button onClick={this.resetError}>Try Again</button>
              </>
            )}
          </div>
        );
      }
  
      return this.props.children;
    }
  }

export default ErrorBoundary;
