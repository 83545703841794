import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { currentApi } from '../../services/Db/dbInfo';
import { checkDuplicateSession } from '../../services/Subscription/subscriptionService';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../firebase/analytics';

function PaymentSuccess() {
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(null);
  const [paymentResponse, setPaymentResponse] = useState<any | null>({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

    if (sessionId) {
      setCheckoutSessionId(sessionId);
    }
  }, []);

  useEffect(() => {
    const getSessionResponse = async () => {
      if (checkoutSessionId) {
        try {
          console.log('checkoutSessionId', checkoutSessionId);
          const duplicateSessionResponse = await checkDuplicateSession(checkoutSessionId);
          console.log('duplicateSessionResponse', duplicateSessionResponse);
          if (
            duplicateSessionResponse &&
            isArray(duplicateSessionResponse.data) &&
            duplicateSessionResponse.data.length > 0
          ) {
            navigate('/dashboard');
            return;
          }
          const response = await axios.get(
            `${currentApi.serverURL}/strapi-stripe/retrieveCheckoutSession/${checkoutSessionId}`
          );
          console.log(response.data); // Do whatever you want with the response data
          if (response) setPaymentResponse(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    getSessionResponse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId]);

  useEffect(() => {
    try {
      if (paymentResponse && paymentResponse.amount_total) {
        const {
          metadata,
          currency,
          amount_total,
          payment_method_types,
        } = paymentResponse;
        logEvent('purchase', {
          currency,
          items: metadata ? metadata.productName : 'Premium member',
          payment_type: payment_method_types,
          value: amount_total,
        });
      }
    } catch (e) {
      console.error('error logging analytics', e);
    }
  }, [paymentResponse]);

  const {
    metadata,
    // amount_subtotal,
    // currency,
    // amount_total,
    // created,
    // customer_details,
    // customer_email,
    // expires_at,
    // invoice,
    // payment_method_types,
    // payment_status,
    // subscription,
  } = paymentResponse;
  return (
    <div className="container p-5">
      <div className="card mt-5">
        <div className="card-header">
          <h4 className="text-center">{t('subscribe.success.title')}</h4>
          <hr className="my-4" />
        </div>
        <div className="card-body">
          <p className="text-center">
            {t('subscribe.success.proddesc').replace(
              '{productname}',
              metadata ? metadata.productName : 'Premium member'
            )}
          </p>
          <p className="text-center">{t('subscribe.success.desc1')}</p>
          <p className="text-center">{t('subscribe.success.desc2')}</p>
        </div>
        <div className="row mt-1">
          <div className="form-group col-md-12">
            <div className="mt-2 mb-3 text-center">
              <button
                type="button"
                className="next btn btn-gold btn-round text-uppercase"
                onClick={() => navigate('/dashboard')}
              >
                {t('subscribe.success.btn')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
