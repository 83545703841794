import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import qs from 'qs';
import axios from '../../lib/client';
import axiosPublicClient from '../../lib/publicClient';
import { toTripDetails, toTripDto } from '../Db/utils';
import { TripDetails } from '../Guide/guide';

const queryKey = ['trips'];
interface ITripsByGuideIdOptions {
  pageIndex: number;
  pageSize: number;
  guideId: number;
}
export default function useTripHook(props: any) {
  const { pageIndex = 1, pageSize = 25, id = undefined, guideId } = props;
  const queryClient = useQueryClient();
  const fetchDataOptions: ITripsByGuideIdOptions = {
    pageIndex,
    pageSize,
    guideId,
  };

  const getTripsByGuideId = useQuery(
    ['trips', i18next.language, fetchDataOptions],
    async () => {
      const queryParams = qs.stringify(
        {
          filters: {
            guide: {
              id: {
                $eq: `${fetchDataOptions.guideId}`,
              },
            },
          },
          fields: [
            'id',
            'name',
            'isSharedTrip',
            'pricePerPerson',
            'priceifNotShared',
            'priceCurrency',
            'isSeasonalTrip',
            'seasonFrom',
            'seasonTo',
            'operateDays',
          ],
          populate:{
            guide_boat: { // - get Id only 
              fields: ['id'],
            },
          },
          pagination: {
            pageSize: `${fetchDataOptions.pageSize}`,
            page: `${fetchDataOptions.pageIndex}`,
          },
          publicationState: 'live',
        },
        { encode: false }
      );

      return await axios
        .get(`/guidetrips?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          console.log('guidetripe', response);
          
          const data = response.data?.data?.map((trip: TripDto) => {
            return toTripDetails(trip);
          });
          return { data: data, meta: response.data?.meta };
        });
    },
    { keepPreviousData: true }
  );

  const getTrip = useQuery(
    [i18next.language, 'trip', id],
    async () => {
      const queryParams = qs.stringify(
        {
          populate: {
            tripMedia: { fields: ['id', 'name', 'url'] },
            targetedSpecies: { fields: ['id', 'name'] },
            fishingTechniques: { fields: ['id', 'name'] },
            fishingTypes: { fields: ['id', 'name'] },
            catchPolicy: { fields: ['id', 'name'] },
            foodAndBeverages: { fields: ['id', 'name'] },
            cancellationPolicy: { fields: ['id', 'name'] },
            pickupPolicy: { fields: ['id', 'name'] },
            guide_boat: {
              populate: {
                boatMedia: { fields: ['id', 'name', 'url'] },
                typeofEngine: { fields: ['id', 'name'] },
                boatType: { fields: ['id', 'name'] },
                navEquipments: { fields: ['id', 'name'] },
                safetyEquipments: { fields: ['id', 'name'] },
                facilities: { fields: ['id', 'name'] },
                fishingGears: { fields: ['id', 'name'] },
                additionalCrew: { fields: ['id', 'name'] },
              },
            },
          },
          publicationState: 'live',
        },
        { encode: false }
      );

      return await axiosPublicClient
        .get(`/guidetrips/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return toTripDetails(response.data?.data);
        });
    },
    { enabled: !!id }
  );

  const postTrip = useMutation(
    async (trip: TripDetails) => {
      let data: any = toTripDto(trip);
      data = {
        ...data,
        seo: [
          {
            metaTitle: data?.name,
            metaDescription: data?.description?.length > 250 ? 
            data?.description.substring(0, 250).split(' ').slice(0, -1).join(' ') + '...' : 
            data?.description,            
            metaImage:data?.tripMedia ? data?.tripMedia[0]?.id : null,
          },
        ],
      };

      await axios
        .post(`/guidetrips?locale=${i18next.language}`, { data: data })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );
  const updateTrip = useMutation(
    async (trip: TripDetails) => {
      let data: any = toTripDto(trip);
      const seoObject: any = {
        metaTitle: data.name,
        metaDescription: data?.description?.length > 250 ? 
        data?.description.substring(0, 250).split(' ').slice(0, -1).join(' ') + '...' : 
        data?.description,      
      };
  
      if (data.tripMedia && data.tripMedia.length > 0 && data.tripMedia[0]?.id) {
        seoObject.metaImage = data.tripMedia[0].id;
      }
      data = {
        ...data,
        seo: [seoObject],
      };
      await axios
        .put(`/guidetrips/${trip.id}?locale=${i18next.language}`, { data: data })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
        queryClient.invalidateQueries(['TranslatedBy', 'guidetrip']);
      },
    }
  );
  const deleteTrip = useMutation(
    async (id: number) => await axios.delete(`/guidetrips/${id}?locale=${i18next.language}`),
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(['getLastDraftVersion']);
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );

  return {
    getTrip,
    getTripsByGuideId,
    postTrip,
    updateTrip,
    deleteTrip,
  };
}
