import parse from 'html-react-parser';
import { Interweave } from 'interweave';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRegister } from '../../lib/auth';
import { currentApi } from '../../services/Db/dbInfo';
import { useGetFilesByFolderPath } from '../../services/Upload/uploadService';
import storage from '../../utils/storage';

type RegisterFormData = { email: string; password: string };

function Register({ isGuide }: { isGuide?: boolean }) {
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = React.useState(isGuide ? 1 : 3);
  const { isError, error, mutate: createAccount, isSuccess, data: registraionData } = useRegister();
  let [searchParams] = useSearchParams();
  const pathName = searchParams.get('next') || isGuide ? '/guides' : '/dashboard';
  const { data: contentfolderImages } = useGetFilesByFolderPath('GuideReg');
  const { data: registrationImages } = useGetFilesByFolderPath('GuestReg');
  const { data: confirmEmailImages } = useGetFilesByFolderPath('ConfirmEmail');
  const refRecaptcha = React.useRef<ReCAPTCHA>(null);

  function getBackGroundImage(index: number) {
    let style = { backgroundImage: `url(img/register${index}.jpg)` };
  
    if (!isGuide && registrationImages && registrationImages.length > 0) {
      style = { backgroundImage: `url(${registrationImages[0].url})` };
    } 
    else if (isGuide && contentfolderImages && contentfolderImages.length > 0) {
      style = { backgroundImage: `url(${contentfolderImages[index - 1].url})` };
    }
    return style;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>();

  const onSubmit = handleSubmit((registerRequest: RegisterFormData) => {
    if (refRecaptcha && refRecaptcha.current) {
      try {
        refRecaptcha.current.execute();
        createAccount(registerRequest, {
          onSuccess: (data) => {
           if(isGuide) storage.addRegisterAsGuide(registerRequest.email);
          },
          onError: (error: any) => {
            console.log(error);
            refRecaptcha?.current?.reset();
          },
        });
      } catch (ex) {
        refRecaptcha.current.reset();
      }
    }
  });

  const renderStep1 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">{parse(t('register_page.step.1.title'))}</h2>

        <p className="registerfont">
          {t('register_page.step.1.description1')} <br />
          {t('register_page.step.1.description2')}
        </p>

        <button className="btn btn-gold mt-3" type="button" onClick={() => setStep(2)}>
          {t('common.next')}
        </button>
        <div className="text-center"></div>
      </div>
    );
  };

  const renderStep2 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">{t('register_page.step.2.title')}</h2>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading1')}</span>
          <br />
          {t('register_page.step.2.heading1_description')}
        </p>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading2')}</span>
          <br />
          {t('register_page.step.2.heading2_description')}
        </p>
        <p className="registerfont">
          <span className="h7">{t('register_page.step.2.heading3')}</span>
          <br />
          {t('register_page.step.2.heading3_description')}
        </p>
        <button className="btn btn-gold mt-3" type="button" onClick={() => setStep(1)}>
          {t('common.previous')}
        </button>
        &nbsp;
        <button className="btn btn-gold mt-3" type="button" onClick={() => setStep(3)}>
          {t('common.next')}
        </button>
        <div className="text-center"></div>
      </div>
    );
  };

  const renderStep3 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">{t('register_page.step.3.title')}</h2>
        <p className="registerfont">{t('register_page.step.3.description')}</p>

        <form
          id="msform"
          onSubmit={onSubmit}
          className={`needs-validation ${
            errors.email?.message || errors.password?.message ? 'was-validated' : ''
          }`}
        >
          <div className="form-label-group">
            <input
              type="email"
              id="inputEmail"
              {...register('email', {
                required: t('validators.mixed.required'),
              })}
              className={`form-control ${errors.email?.message ? 'is-invalid' : ''}`}
              placeholder={t('user.fields.email.placeholder')}
              required
              autoFocus
            />
            <label htmlFor="inputEmail">{t('user.fields.email.label')}</label>
          </div>
          <div className="form-label-group">
            <input
              type="password"
              id="inputPassword"
              {...register('password', {
                required: t('validators.mixed.required'),
              })}
              className={`form-control ${errors.password?.message ? 'is-invalid' : ''}`}
              placeholder={t('user.fields.password.label')}
              required
            />
            <label htmlFor="inputPassword">{t('user.fields.password.label')}</label>
          </div>
          <ReCAPTCHA
            ref={refRecaptcha}
            sitekey={`${process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}`}
            onChange={(value) => {
              if (value === null) setSubmitDisabled(true);
            }}
            asyncScriptOnLoad={() => {
              setSubmitDisabled(false);
            }}
            size={process.env.REACT_APP_RECAPTCHA_SIZE === 'normal' ? 'normal' : 'invisible'}
          />
          {isError && (
            <div
              style={{
                display: 'block',
                textAlign: 'center',
                color: 'red',
              }}
            >
              {error?.message}
            </div>
          )}
          <button disabled={submitDisabled} className="btn mt-2 btn-gold mb-2" type="submit">
            {t('common.register')}
          </button>
          <hr className="my-4" />
          <div className="d-grid gap-2">
            <button
              className="btn btn-google btn-block "
              type="button"
              onClick={() => {
                storage.socialMediaLogin();
                window.location.href = `${currentApi.baseUriValue}/connect/google`}}
            >
              <i className="fab fa-google mr-2"></i> {t('common.register_with_google')}
            </button>
          </div>
          <div className="d-grid gap-2">
            <button
              className="btn btn-facebook btn-block "
              type="button"
              onClick={() => {
                storage.socialMediaLogin();
                window.location.href = `${currentApi.baseUriValue}/connect/facebook`}}
            >
              <i className="fab fa-facebook mr-2"></i> {t('common.register_with_facebook')}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderStep4 = () => {
    return (
      <div className="col-md-9 col-lg-8 mx-auto">
        <h2 className="login-heading mt-5 mb-4">
          {t('register_page.step.4.sucess_message_title')}
        </h2>
        <p className="registerfont">{t('register_page.step.4.sucess_message_description')}</p>
        <button className="btn  btn-gold mb-2" type="button" onClick={() => navigate(pathName)}>
          {t('common.add_listing')}
        </button>
      </div>
    );
  };

  const onSuccess = (email: string) => {
    return (
      <div className="container-fluid">
        <div className="row no-gutter">
          <div
            className={`d-none d-md-flex col-md-4 col-lg-6 register-bg-image${step}`}
            style={
               confirmEmailImages && confirmEmailImages.length > 0
                ? { backgroundImage: `url(${confirmEmailImages[0].url})` }
                : getBackGroundImage(step)
            }
          ></div>          
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mt-5 mb-4">
                      {t('register_page.emailconfirmation.title')}
                    </h2>
                    <Interweave
                      content={t('register_page.emailconfirmation.description1').replace(
                        '{{email}}',
                        `${email}`
                      )}
                      className="registerfont"
                      tagName="p"
                    />

                    <p className="registerfont">
                      {t('register_page.emailconfirmation.description2')}
                    </p>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isSuccess) return onSuccess(registraionData?.email || '');

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div
          className={`d-none d-md-flex col-md-4 col-lg-6 register-bg-image`}
          style={getBackGroundImage(step)}
        ></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                {step === 1
                  ? renderStep1()
                  : step === 2
                  ? renderStep2()
                  : step === 3
                  ? renderStep3()
                  : renderStep4()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
